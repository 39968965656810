<template>
  <div class="toolbar clearfix">
    <sorter />
    <template v-if="caseScope">
      <case-type-filter :all-case-types="caseTypeFilters" />
      <case-device-filter
        :all-case-devices="caseDeviceFilters"
        :current-case-type="currentCaseType"
        @updateDeviceSlug="updateDeviceSlug"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CaseDeviceFilter from '@/components/catalog/toolbar/case-device-filter/CaseDeviceFilter.vue'
import Sorter from '~/components/catalog/toolbar/Sorter.vue'
import CaseTypeFilter from '~/components/catalog/toolbar/CaseTypeFilter.vue'
import appendUrlParam from '~/utils/appendUrlParam'

export default {
  components: {
    Sorter,
    CaseTypeFilter,
    CaseDeviceFilter,
  },
  props: {
    caseScope: {
      type: Boolean,
      default: true,
    },
  },
  async fetch() {
    try {
      const params = {
        ...this.$store.getters.storeInfo,
      }
      const endpointUrl = appendUrlParam(`/catalog/case_type_filters`, params)

      const filtersData = await this.$axios.$get(endpointUrl)

      this.caseTypeFilters = filtersData.case_type_filters
      this.caseDeviceFilters = filtersData.case_device_filters

      const includesCaseType = filtersData.case_type_filters.some(
        (case_type) => case_type.code === this.$route.query.case_type // eslint-disable-line camelcase
      )
      this.currentCaseType = includesCaseType
        ? this.$route.query.case_type
        : 'standard'
    } catch (err) {
      this.$displayTopMessage(this.$t('action_error'), {
        type: 'error',
      })
    }
  },
  data() {
    return {
      caseTypeFilters: [],
      caseDeviceFilters: [],
      currentCaseType: '',
    }
  },
  computed: {
    ...mapState(['storeCode', 'locale']),
  },
  watch: {
    '$route.query': '$fetch',
  },
  methods: {
    updateDeviceSlug(deviceSlug) {
      this.$emit('updateDeviceSlug', deviceSlug)
    },
  },
  // Todo: Adjust spree cors settings
  // fetchOnServer: false,
}
</script>

<style lang="scss">
@import '~/assets/css/_toolbar.scss';
</style>
