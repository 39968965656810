<template>
  <nav
    v-if="shouldDisplay"
    id="taxonomies"
    class="category-tree sidebar-widget"
  >
    <taxon-menu-item
      v-for="taxon in taxons"
      :key="taxon.id"
      :taxon="taxon.root"
      :selected-id="currentTaxonId"
    />
  </nav>
</template>

<script>
import TaxonMenuItem from '~/components/catalog/navigator/TaxonMenuItem.vue'

const components = {
  TaxonMenuItem,
}

export default {
  name: 'TaxonMenu',
  components,
  props: {
    /** See ~/test/fixtures/taxons.json */
    taxons: {
      type: Array,
      default: () => [],
    },
    currentTaxon: {
      type: Object,
      default: null,
    },
  },

  computed: {
    shouldDisplay() {
      return this.taxons?.length > 0
    },
    currentTaxonId() {
      return this.currentTaxon?.id ?? null
    },
  },
}
</script>
