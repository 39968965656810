<template>
  <div class="toolbar__item">
    <drop-down
      class="hide-mobile"
      :active-item="{
        title: currentSortOption.name,
      }"
      :items="toolbarItemOptions"
      :closeable="$parent"
      show-toggle-button
      block
      left-icon-class="arrow_up_down-icon"
      @action="reloadPage"
    />
  </div>
</template>

<script>
import appendUrlParam from '~/utils/appendUrlParam'
import DropDown from '~/components/DropDown.vue'

export default {
  components: { DropDown },
  data() {
    return {
      sortOptions: [
        {
          name: this.$t('sort_options.best_sellers'),
          key: 'favorites',
        },
        {
          name: this.$t('sort_options.newest'),
          key: 'newest',
        },
        {
          name: this.$t('sort_options.name'),
          key: 'name',
        },
        {
          name: this.$t('sort_options.relevance'),
          key: 'relevance',
        },
      ],
      open: false,
    }
  },
  computed: {
    toolbarItemOptions() {
      return this.sortOptions.map((option) => {
        return this.toolbarItemStructure(option)
      })
    },
    currentSortOption() {
      const options = this.sortOptions
      return (
        options.find((option) => option.key === this.$route.query.order) ||
        options.find((option) => option.key === 'favorites')
      )
    },
  },
  methods: {
    toolbarItemStructure(option) {
      return {
        title: option.name,
        key: option.key,
        link: this.sortUrl(option.key),
      }
    },
    sortUrl(sortKey) {
      const currentPath = this.$route.path
      const queryParams = this.$route.query

      return appendUrlParam(currentPath, { ...queryParams, order: sortKey })
    },
    reloadPage() {
      window.location.reload()
    },
  },
}
</script>
