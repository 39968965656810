<template>
  <div class="toolbar__item">
    <drop-down
      :active-item="{
        title: optionName(currentCaseTypeOption),
      }"
      :items="toolbarItemOptions"
      :closeable="$parent"
      show-toggle-button
      block
    />
  </div>
</template>

<script>
import appendUrlParam from '~/utils/appendUrlParam'
import DropDown from '~/components/DropDown.vue'

export default {
  name: 'CaseTypeFilter',
  components: {
    DropDown,
  },
  props: {
    allCaseTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      caseTypes: this.allCaseTypes,
    }
  },
  computed: {
    availableCaseTypes() {
      return [this.voidCaseType, ...this.caseTypes]
    },
    voidCaseType() {
      return {
        code: 'any',
        preferred_device: null,
        void: true,
      }
    },
    toolbarItemOptions() {
      return this.availableCaseTypes.map((caseType) => {
        return this.toolbarItemStructure(caseType)
      })
    },
    currentCaseTypeOption() {
      const caseTypes = this.availableCaseTypes
      return (
        caseTypes.find(
          (caseType) => caseType.code === this.$route.query.case_type
        ) || caseTypes.find((caseType) => caseType.code === 'any')
      )
    },
  },
  watch: {
    allCaseTypes(newValue, oldValue) {
      this.caseTypes = newValue
    },
  },
  methods: {
    toolbarItemStructure(caseType) {
      return {
        title: this.optionName(caseType),
        key: caseType.code,
        link: this.mountCaseTypeUrl(caseType),
      }
    },
    mountCaseTypeUrl(caseType) {
      const currentPath = this.$route.path
      const queryParams = { ...this.$route.query }

      if (caseType.void) {
        delete queryParams.case_type
        delete queryParams.device
      } else {
        queryParams.case_type = caseType.code
        queryParams.device = caseType.preferred_device
      }
      return appendUrlParam(currentPath, queryParams)
    },
    optionName(caseType) {
      let name
      if (caseType.void) {
        name = this.currentCaseTypeOption.void
          ? this.$t('filter_options.case_type_filter.prompt')
          : this.$t('filter_options.case_type_filter.all_types')
      } else name = caseType.name
      return name
    },
  },
}
</script>

<style></style>
