var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products-grid"},[_c('div',{class:[
      'products-grid__items',
      {
        'products-grid__items--one-columns': _vm.maxGridColumns === 1,
        'products-grid__items--two-columns': _vm.maxGridColumns === 2,
        'products-grid__items--three-columns': _vm.maxGridColumns === 3,
        'products-grid__items--four-columns': _vm.maxGridColumns === 4,
        'products-grid__items--mobile-one-columns':
          _vm.mobileMaxGridColumns === 1,
        'products-grid__items--mobile-two-columns':
          _vm.mobileMaxGridColumns === 2,
        'products-grid__items--mobile-three-columns':
          _vm.mobileMaxGridColumns === 3,
        'products-grid__items--mobile-four-columns':
          _vm.mobileMaxGridColumns === 4,
      } ],attrs:{"id":"products"}},_vm._l((_vm.products),function(p,index){return _c('product',{key:p.id,class:[
        'products-grid__item',
        _vm.alternatedCssClasses(index),
        { 'catalog__item--goforgood': _vm.goForGoodCatalog } ],attrs:{"product":p,"catalog-has-badge":_vm.catalogHasBadge,"type":'catalogProduct',"case-device-id":_vm.caseDeviceId,"send-gtm-data":true,"device-slug":_vm.deviceSlug,"show-sold-off":_vm.showSoldOff,"show-all-colors":_vm.allowAllColors}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }