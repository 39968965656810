<template>
  <aside id="navigator" class="sidebar">
    <div
      ref="modalNav"
      :class="['modal-nav', { 'modal-nav--open': navIsOpened }]"
    >
      <a
        class="modal-nav__toggle"
        href="#"
        rel="nofollow"
        @click="toggleModalNav"
      >
        {{ $t('navigator.sidebar_categories_title') }}
        <icon-arrow-down />
      </a>
      <h4 class="sidebar-widget__title hide-mobile">
        {{ $t('navigator.sidebar_categories_title') }}
      </h4>
      <div class="modal-nav__modal">
        <div class="modal-nav__header">
          {{ $t('navigator.sidebar_categories_title') }}
          <a
            href="#"
            rel="nofollow"
            class="modal-nav__close"
            @click="toggleModalNav"
          >
            &#10005;
          </a>
        </div>
        <div class="modal-nav__body">
          <product-filter-menu
            :loading="$fetchState.pending"
            :product-filters="productFilters"
            :current-product-filter="productFilter"
          />
          <taxon-menu :taxons="taxons" :current-taxon="taxon" />
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import ProductFilterMenu from '~/components/catalog/navigator/ProductFilterMenu.vue'
import TaxonMenu from '~/components/catalog/navigator/TaxonMenu.vue'
import isPresent from '~/utils/isPresent'
import IconArrowDown from '~/components/icons/toolbar/ArrowDown'
import modalable from '~/mixins/modalable'

export default {
  name: 'Navigator',
  components: {
    ProductFilterMenu,
    TaxonMenu,
    IconArrowDown,
  },
  mixins: [modalable],
  props: {
    taxon: {
      type: [Object, String],
      default: null,
    },
    productFilter: {
      type: [Object, String],
      default: null,
    },
    caseDevice: {
      type: String,
      default: '',
    },
    productIds: {
      type: Array,
      default: () => [],
    },
  },
  async fetch() {
    await this.fetchMethod()
  },
  data() {
    return {
      taxons: [],
      productFilters: [],
      navIsOpened: false,
    }
  },
  computed: {
    productFilterSlug() {
      return this.getSlug(this.productFilter)
    },
    taxonSlug() {
      return this.getSlug(this.taxon)
    },
  },
  watch: {
    navIsOpened(newBool) {
      this.modalIsOpened(newBool)
    },
  },
  methods: {
    isPresent,
    getSlug(object) {
      return typeof object === 'object'
        ? object?.slug || object?.permalink
        : object || ''
    },
    async fetchMethod() {
      try {
        const params = {
          ...this.$store.getters.storeInfo,
          product_filter: this.productFilterSlug,
          case_device: this.caseDevice,
          taxon: this.taxonSlug,
        }
        const {
          product_filter: productFilters,
          taxon,
        } = await this.$axios.$get('/catalog/catalog-navigator', { params })
        this.productFilters = productFilters
        this.taxons = taxon
      } catch (err) {
        this.$displayTopMessage(this.$t('action_error'), {
          type: 'error',
        })
      }
    },
    toggleModalNav() {
      this.navIsOpened = !this.navIsOpened
    },
  },
  fetchOnServer: false,
}
</script>

<style lang="scss">
@import '~/assets/css/_section.scss';
@import '~/assets/css/_category-tree.scss';
@import '~/assets/css/modal-nav.scss';

.sidebar-widget {
  margin-bottom: 20px;
}

.sidebar-widget__title {
  border-bottom: 1px solid #eee;
  padding: 0 0 5px;
  margin: 0 0 10px;
  color: #999;
  font-size: 11px;
  text-transform: uppercase;
}

@media (min-width: $screen_tablet) {
  .sidebar {
    display: block;
    float: left;
    width: 30%;
    padding-right: 20px;
    padding-top: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media (min-width: $screen_large) {
  .sidebar {
    width: 25%;
    padding-right: 30px;
    padding-top: 30px;
  }
}
</style>
