<template>
  <div :class="['catalog__description', 'see-more', { expanded: visibleText }]">
    <!--  eslint-disable-next-line vue/no-v-html -->
    <div ref="seeMore" class="see-more__content" v-html="text"></div>

    <div
      v-show="displayLink"
      :class="['see-more__link', { active: activeLink }]"
      @click="toggleSeeMore"
    >
      <span class="see-more__more-text">{{
        $t('see_more.see_more_text')
      }}</span>
      <span class="see-more__less-text">{{
        $t('see_more.see_less_text')
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      activeLink: false,
      visibleText: false,
      displayLink: true,
    }
  },
  mounted() {
    this.handleSeeMore()
  },
  methods: {
    handleSeeMore() {
      if (this.shouldToggleContent()) {
        this.visibleText = true
        this.displayLink = false
      }
    },
    toggleSeeMore(event) {
      this.activeLink = !this.activeLink
      this.visibleText = !this.visibleText
    },
    shouldToggleContent() {
      const content = this.$refs.seeMore
      return content && content.scrollHeight <= 40
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/css/see-more.scss';
</style>
