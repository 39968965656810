<template>
  <div class="product-filter-page">
    <new-catalog-page
      v-if="useCatalogPageWithFilters"
      :taxon="taxon"
      :title="productFilter.name"
      :main-description="mainDescription"
      :product-filter-slug="productFilter.slug"
      :product-base-id="productFilter.product_base_id"
      :variant-base-id="productFilter.variant_base_id"
      :materials-filter="productFilter.materials_filter"
      :product-filter-taxon-id="productFilter.product_filter_taxon_id"
      :translation-prefix="translationPrefix"
      :use-case-scope="productFilter.use_case_scope"
      :use-kit-taxons="productFilter.is_kit_page"
      :enable-360-print-filter="
        isBottlePage || productFilter.enable_360_print_filter
      "
      :allow-all-colors="productFilter.allow_all_colors"
      :is-bottle-page="isBottlePage"
      :is-urban-bottle-page="isUrbanBottlePage"
      :is-power-bank-page="isPowerBankPage"
    />
    <!-- <catalog-page-with-filters
      v-if="useCatalogPageWithFilters"
      :title="productFilter.name"
      :see-more-text="pageDescription"
      :banner="productFilter.banner_url"
      :product-filter="productFilter"
      :taxon="taxon"
      :send-gtm-data="true"
      :case-scope="productFilter.use_case_scope"
      :is-product-filter="true"
    /> -->
    <catalog-page
      v-else
      :title="productFilter.name"
      :product-filter="productFilter"
      :taxon="taxon"
      :see-more-text="pageDescription"
      :case-scope="false"
      :banner="productFilter.banner_url"
      :send-gtm-data="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CatalogPage from '~/components/catalog/CatalogPage.vue'
// import CatalogPageWithFilters from '~/components/catalog/CatalogPageWithFilters.vue'
import NewCatalogPage from '~/components/new-catalog/NewCatalogPage.vue'

export default {
  components: {
    // CatalogPageWithFilters,
    NewCatalogPage,
    CatalogPage,
  },
  props: {
    productFilter: {
      type: Object,
      required: true,
    },
    mainDescription: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      taxons: [],
      products: [],
    }
  },
  computed: {
    ...mapGetters(['storeInfo']),
    translationPrefix() {
      if (this.isCasePage) return 'case'
      if (this.isBottlePage || this.isUrbanBottlePage) return 'bottle'
      return 'generic'
    },
    isCasePage() {
      return ['capinha-para-celular', 'phone-cases'].includes(
        this.productFilter.slug
      )
    },
    isBottlePage() {
      return [
        'thermos-bottles',
        'garrafas-termicas',
        'garrafas-termicas-ebook',
        'thermos-bottles-ebook',
      ].includes(this.productFilter.slug)
    },
    isUrbanBottlePage() {
      return ['garrafas-urban', 'garrafas-urban-ebook'].includes(
        this.productFilter.slug
      )
    },
    isPowerBankPage() {
      return ['carregadores-portateis'].includes(this.productFilter.slug)
    },
    useCatalogPageWithFilters() {
      return (
        this.productFilter.enable_new_filter_page ||
        ((this.isCasePage ||
          this.isBottlePage ||
          this.isPowerBankPage ||
          this.isUrbanBottlePage) &&
          this.$isBrStore)
      )
    },
    pageDescription() {
      return (
        // eslint-disable-next-line camelcase
        this.productFilter?.page_description ||
        // eslint-disable-next-line camelcase
        this.productFilter?.seo_description
      )
    },
    taxon() {
      return {
        id: this.taxonId,
        permalink: this.taxonSlug,
      }
    },
    taxonId() {
      return this.productFilter.taxon_id
    },
    taxonSlug() {
      return this.productFilter.taxon_slug
    },
  },
}
</script>
