<template>
  <div class="toolbar__item">
    <drop-down
      :active-item="{
        title: optionName(currentCaseDeviceOption),
      }"
      :items="toolbarItemOptions"
      :closeable="$parent"
      show-toggle-button
      left-icon-class="phone-icon"
      block
    />
  </div>
</template>

<script>
import appendUrlParam from '~/utils/appendUrlParam'
import DropDown from '~/components/DropDown.vue'
import stringToSlug from '~/utils/stringToSlug'

export default {
  name: 'CaseDeviceFilter',
  components: {
    DropDown,
  },
  props: {
    allCaseDevices: {
      type: Array,
      required: true,
    },
    currentCaseType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      caseDevices: this.allCaseDevices,
      open: false,
    }
  },
  computed: {
    availableCaseDevices() {
      return [this.voidCaseDevice, ...this.caseDevices]
    },
    voidCaseDevice() {
      return {
        code: 'any',
        void: true,
      }
    },
    toolbarItemOptions() {
      return this.availableCaseDevices.map((caseDevice) => {
        return this.toolbarItemStructure(caseDevice)
      })
    },
    currentCaseDeviceOption() {
      const caseDevices = this.availableCaseDevices
      try {
        const final =
          caseDevices.find(
            (caseDevice) => caseDevice.code === this.$route.query.device
          ) || caseDevices.find((caseDevice) => caseDevice.code === 'any')
        return final
      } catch (err) {
        return false
      }
    },
  },
  watch: {
    allCaseDevices(newValue, oldValue) {
      this.caseDevices = newValue
    },
    currentCaseDeviceOption(newCaseDeviceOption) {
      if (newCaseDeviceOption.void) return

      this.$emit(
        'updateDeviceSlug',
        stringToSlug(newCaseDeviceOption.device_name)
      )
    },
  },
  created() {
    const device = this.$route.query.device
    if (device) {
      const currentDevice = this.allCaseDevices.find((cd) => cd.code === device)
      if (currentDevice) {
        this.$emit('updateDeviceSlug', stringToSlug(currentDevice.device_name))
      }
    }
  },
  methods: {
    toolbarItemStructure(caseDevice) {
      return {
        title: this.optionName(caseDevice),
        key: caseDevice.code,
        link: this.mountCaseDeviceUrl(caseDevice),
      }
    },
    mountCaseDeviceUrl(caseDevice) {
      const currentPath = this.$route.path
      const queryParams = { ...this.$route.query }

      if (caseDevice.void) {
        delete queryParams.case_type
        delete queryParams.device
      } else {
        queryParams.case_type = queryParams.case_type || this.currentCaseType
        queryParams.device = caseDevice.code
      }
      return appendUrlParam(currentPath, queryParams)
    },
    optionName(caseDevice) {
      let name
      if (caseDevice.void) {
        name = this.currentCaseDeviceOption.void
          ? this.$t('filter_options.device_filter.prompt')
          : this.$t('filter_options.device_filter.all_devices')
      } else name = caseDevice.device_name
      return name
    },
  },
}
</script>

<style></style>
