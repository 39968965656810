export default {
  watch: {
    '$route.query'({ page: newPage }, { page: oldPage }) {
      if (!newPage) return
      this.$fetch()

      if (newPage !== oldPage)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
    },
  },
}
