export default {
  data() {
    return {
      translateX: 0,
      baseWidth: 380 + 30,
      maximumTranslate: 0,
    }
  },
  methods: {
    navGallery(diff) {
      this.maximumTranslate = this.getMaximumTranslate()
      let newValue = this.translateX + diff * this.baseWidth

      // Scrolling over the rainbow
      if (Math.abs(newValue) > this.maximumTranslate) {
        // It's ats limit, let's reset
        if (Math.abs(this.translateX) === this.maximumTranslate) {
          newValue = 0
        } else {
          newValue = -this.maximumTranslate
        }
      } else if (newValue > 0) {
        // Scrolling back to the past
        newValue = 0
      }

      this.updateSelectorScroll(newValue)
    },
    getMaximumTranslate() {
      const list = document.querySelector('.' + this.listClassName)
      const scrollWidth = list.scrollWidth
      const clientWidth = list.clientWidth

      return Math.abs(scrollWidth - clientWidth)
    },
    updateSelectorScroll(newValue) {
      if (!newValue) newValue = 0

      this.translateX = newValue

      const list = document.querySelector('.' + this.listClassName)

      list.style.transform = `translate3d(${this.translateX}px, 0, 0)`
    },
  },
}
