<template>
  <div
    v-click-outside="clickedOutside"
    :class="[handleOpenPosition, 'dropdown', dropDownCssConfigs]"
    data-grass="dropdown-toggle"
  >
    <div v-if="loading" :class="['dropdown__toggle', toggleCssConfigs]">
      <three-dots-loading />
    </div>
    <a
      v-else
      href="#"
      rel="nofollow"
      :class="['dropdown__toggle', toggleCssConfigs]"
      @click.prevent="toogleDropDown"
    >
      <i
        v-if="leftIconClass === 'arrow_up_down-icon'"
        :class="[
          leftIconClass,
          'icon',
          'icon--margin-right',
          'dropdown__toggle-icon',
        ]"
      >
        <icon-up-down />
      </i>

      <i
        v-if="leftIconClass === 'phone-icon'"
        :class="[
          leftIconClass,
          'icon',
          'icon--margin-right',
          'dropdown__toggle-icon',
        ]"
      >
        <icon-phone />
      </i>

      <img
        v-if="hasImageIcon && activeItem.icon"
        class="dropdown-icon"
        :src="activeItem.icon"
        :alt="activeItem.title"
      />

      <span v-else-if="hasIcon && activeItem.icon" class="dropdown-icon">{{
        activeItem.icon
      }}</span>
      {{ activeItem.title }}

      <i
        v-if="showToggleButton"
        class="dropdown__carret dropdown__carret--open"
      >
        <icon-arrow-down />
      </i>

      <i
        v-if="showToggleButton"
        class="dropdown__carret dropdown__carret--close"
      >
        <icon-arrow-up />
      </i>
    </a>

    <ul class="dropdown__menu dropdown__menu--large">
      <li
        v-for="(item, idx) in items"
        :key="idx"
        class="dropdown__menu__item"
        data-grass="dropdown-item"
        @click.prevent="$emit('action', item)"
      >
        <nuxt-link
          :to="item.link || '#'"
          :event="item.link ? this : ''"
          @click.native="close()"
        >
          <img
            v-if="hasImageIcon && item.icon"
            :src="item.icon"
            :alt="item.title"
          />
          <span v-else-if="hasIcon && item.icon">{{ item.icon }}</span>
          {{ item.title }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import clickOutside from '~/mixins/click-outside'
import IconArrowUp from '~/components/icons/toolbar/ArrowUp'
import IconArrowDown from '~/components/icons/toolbar/ArrowDown'
import IconUpDown from '~/components/icons/toolbar/ArrowUpDown'
import IconPhone from '~/components/icons/toolbar/Phone'
import ThreeDotsLoading from '~/components/loadings/three-dots/ThreeDotsLoading.vue'

const AVAILABLE_OPEN_POSITIONS = ['bottom', 'top', 'left']

export default {
  name: 'DropDown',
  components: {
    IconArrowUp,
    IconArrowDown,
    IconUpDown,
    IconPhone,
    ThreeDotsLoading,
  },
  mixins: [clickOutside],
  props: {
    hasIcon: {
      type: Boolean,
      default: false,
    },
    hasImageIcon: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    activeItem: {
      type: Object,
      required: true,
    },
    openPosition: {
      type: String,
      default: AVAILABLE_OPEN_POSITIONS[0],
      validator(value) {
        return AVAILABLE_OPEN_POSITIONS.includes(value)
      },
    },
    showToggleButton: {
      type: Boolean,
      default: false,
    },
    leftIconClass: {
      type: String,
      default: '',
    },
    closeable: {
      type: Object,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },
  computed: {
    handleOpenPosition() {
      switch (this.openPosition) {
        case AVAILABLE_OPEN_POSITIONS[1]:
          return 'dropdown--open-top'
        case AVAILABLE_OPEN_POSITIONS[2]:
          return 'dropdown--open-left'
        default:
          return ''
      }
    },
    toggleCssConfigs() {
      return {
        'dropdown__toggle--icon-left': !!this.leftIconClass,
        'dropdown__toggle--block': this.block,
      }
    },
    dropDownCssConfigs() {
      return {
        open: this.isOpened,
        ...this.$attrs.class,
        'dropdown--block': this.block,
      }
    },
  },
  methods: {
    close() {
      this.isOpened = false
    },
    toogleDropDown(e) {
      this.isOpened = !this.isOpened
    },
    clickedOutside() {
      this.isOpened = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/variables';

.dropdown {
  display: inline-block;
  position: relative;

  &.dropdown--block {
    display: block;
  }

  &.open {
    .dropdown__carret--open {
      display: none;
    }
    .dropdown__carret--close {
      display: inline-block;
    }
    .dropdown__toggle-icon {
      svg {
        fill: white;
      }
    }

    .dropdown__menu {
      display: block;
    }
  }

  &.dropdown--white.dropdown {
    &.open .dropdown__toggle {
      background-color: white;
      color: #333;
    }
    .dropdown__menu {
      background-color: white;
    }
  }
}

.dropdown-icon {
  margin-right: 5px;
}

.dropdown__toggle {
  display: flex;
  background: $color_gray;
  padding: 12px 18px 12px;
  height: $input_height;
  box-sizing: border-box;
  color: #666;
  line-height: 22px;
  min-width: 70px;
  justify-content: space-evenly;
  align-items: center;

  &.dropdown__toggle--block {
    display: block;
  }

  &:hover {
    background: darken($color_gray, 5%);
  }

  &.dropdown__toggle--icon-left {
    padding-left: 40px;
  }
}
.dropdown-toggle__icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.dropdown.open .dropdown__toggle {
  background: $color_primary;
  color: white;
}

.dropdown__carret {
  height: 14px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
  line-height: initial;
  margin-top: -4px;

  svg {
    fill: #555;
  }

  &--close {
    display: none;
    svg {
      fill: white;
    }
  }
}

.dropdown__toggle-icon {
  position: absolute;
  top: 14px;
  left: 15px;

  svg {
    fill: #555;
    display: block;
  }
}

.dropdown.dropdown--open-top .dropdown__menu {
  top: auto;
  bottom: 100%;
  box-shadow: none;
}
.dropdown.dropdown--open-left .dropdown__menu {
  right: 0;
}

.dropdown__menu {
  display: none;
  position: absolute;
  top: 100%;
  background: $color_primary;
  border: 0;
  box-shadow: 0 1px 3px 0 rgba(27, 36, 50, 0.4);
  list-style-type: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
  max-height: 400px;
  overflow: auto;
  z-index: 9;

  &.dropdown__menu--large {
    min-width: 100%;
  }
}
.dropdown__toggle,
.dropdown__menu__item a {
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
}
.dropdown__menu .dropdown__menu__item:last-child {
  border-bottom: 0;
}
.dropdown .dropdown__menu__item a {
  text-align: left;
  color: white;
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid darken($color_primary, 3%);

  &:hover {
    background: darken($color_primary, 5%);
    border-bottom-color: darken($color_primary, 5%);
  }
}
.dropdown.open .dropdown__menu {
  display: block;
}

@media (min-width: $screen_tablet) {
  .dropdown__menu.dropdown__menu--large {
    min-width: 280px;
  }
  .dropdown--only-mobile {
    display: block;

    .dropdown__toggle {
      display: none;
    }
    .dropdown__menu {
      display: block;
      box-shadow: none;
      position: static;
      margin-bottom: 20px;
    }
  }
}

.dropdown__menu::-webkit-scrollbar {
  width: 12px;
}

.dropdown__menu::-webkit-scrollbar-track {
  background: #4d4d4d;
}

.dropdown__menu::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
}
</style>
