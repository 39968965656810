var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isPresent(_vm.productFilter))?_c('div',[_c('nuxt-link',{class:[
      'category-tree__item',
      {
        'category-tree__item--active': _vm.isSelected(_vm.productFilter.id),
      } ],attrs:{"to":_vm.navigatorLink(_vm.productFilter.permalink)}},[_vm._v("\n    "+_vm._s(_vm.productFilter.name)+"\n  ")]),_vm._v(" "),(
      _vm.isPresent(_vm.productFilter.children) ||
      (_vm.selectedProductFilter &&
        _vm.selectedProductFilter.parent_id === _vm.productFilter.id)
    )?_c('div',{staticClass:"category-tree__children"},_vm._l((_vm.productFilter.children),function(child){return _c('nuxt-link',{key:child.id,class:[
        'category-tree__item',
        { 'category-tree__item--active': _vm.isSelected(child.id) } ],attrs:{"to":_vm.navigatorLink(child.permalink)}},[_c('div',{staticClass:"category-tree__item-arrow"}),_vm._v("\n      "+_vm._s(child.name)+"\n    ")])}),1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }