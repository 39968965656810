<template>
  <div id="promotion-event">
    <banners v-if="promotionBanners.length" :banners="promotionBanners" />
    <div class="end-in__container">
      <div v-if="$t('timer.expired') !== timer" class="container">
        <h3 class="end-in__label">
          {{ $t('promotion.end_in') }}
          <span class="promotion_end_in">
            {{ timer }}
          </span>
        </h3>
      </div>
    </div>
    <div class="container" data-hook>
      <div class="content">
        <products-grid
          :products="eventProducts"
          :max-grid-columns="4"
          :show-sold-off="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import isPresent from '~/utils/isPresent'
import timer from '~/mixins/timer'
import Banners from '~/components/pages/banners/Banners.vue'
import ProductsGrid from '~/components/ProductsGrid.vue'
import positionable from '~/mixins/positionable'
import pushDataLayer from '~/mixins/gtm/push-data-layer'

export default {
  name: 'PromotionEventPage',
  components: {
    Banners,
    ProductsGrid,
  },
  mixins: [timer, positionable, pushDataLayer],
  props: {
    promotionEvent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    promotionBanners() {
      const positionedBanners = this.positioning([
        ...this.promotionEvent.banners.banners,
      ])
      return this.adaptBanners(positionedBanners)
    },
    eventProducts() {
      return this.promotionEvent.event_products.filter((prod) =>
        this.isPresent(prod)
      )
    },
    endDate() {
      return this.promotionEvent.promotion.end_date
    },
  },
  created() {
    this.startTimer()
  },
  mounted() {
    this.sendToGtm()
  },
  methods: {
    isPresent,
    adaptBanners(banners) {
      const { isAndroid, isIos } = this.$device
      const availableContexts = isAndroid
        ? ['ANY', 'ANDROID']
        : isIos
        ? ['ANY', 'IOS']
        : ['ANY', 'ANDROID', 'IOS']

      const adaptedBanners = banners
        .filter(
          (banner) =>
            banner.published && availableContexts.includes(banner.context)
        )
        .map((banner) => {
          banner.desktop_image = banner.images.desktop
          banner.mobile_image = banner.images.mobile
          return banner
        })

      return adaptedBanners
    },
    sendToGtm() {
      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'navigation',
        eventAction: 'viewPromotionEvent',
        eventValue: {},
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.end-in__container {
  margin-top: -5px;
}

.spinner {
  display: inline-block;
  margin-left: 15px;
}
</style>
