<template>
  <div v-if="isPresent(productFilter)">
    <nuxt-link
      :to="navigatorLink(productFilter.permalink)"
      :class="[
        'category-tree__item',
        {
          'category-tree__item--active': isSelected(productFilter.id),
        },
      ]"
    >
      {{ productFilter.name }}
    </nuxt-link>
    <div
      v-if="
        isPresent(productFilter.children) ||
        (selectedProductFilter &&
          selectedProductFilter.parent_id === productFilter.id)
      "
      class="category-tree__children"
    >
      <nuxt-link
        v-for="child in productFilter.children"
        :key="child.id"
        :to="navigatorLink(child.permalink)"
        :class="[
          'category-tree__item',
          { 'category-tree__item--active': isSelected(child.id) },
        ]"
      >
        <div class="category-tree__item-arrow"></div>
        {{ child.name }}
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import isPresent from '~/utils/isPresent'
export default {
  props: {
    productFilter: {
      type: Object,
      default: null,
    },
    selectedProductFilter: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    isPresent,
    isSelected(id) {
      return Number(this.selectedProductFilter?.id) === Number(id)
    },
    navigatorLink(permalink) {
      return `/${permalink}`
    },
  },
}
</script>

<style lang="scss" scoped>
.category-tree__item {
  display: flex;
  align-items: center;
}
.category-tree__item-arrow {
  margin-left: 5px;
  margin-right: 5px;
  width: 5px;
  height: 7px;
  color: #555;
  background: url('~@/assets/images/icons/header-menu/arrow-right.svg')
    center/contain no-repeat;
}
</style>
