<template>
  <div>
    <div class="container">
      <navigator
        :product-filter="productFilter"
        :taxon="taxon"
        :product-ids="productIds"
        :case-device="caseDeviceParam"
      />
      <div v-if="noData" id="content" class="content content__with-sidebar">
        <div class="info-panel info-panel--solo">
          <p>{{ $t('invalid_device.title') }} 😔</p>
          <ul>
            <li>{{ $t('invalid_device.wrong') }}</li>
            <li>{{ $t('invalid_device.unsupported') }}</li>
          </ul>
        </div>
      </div>
      <div v-else class="content content__with-sidebar">
        <div class="page-header page-header--left page-header--small">
          <br />
          <h1 class="taxon-title">{{ title }}</h1>
        </div>
        <div
          v-if="$store.state.userInteracted && isPresent(banner)"
          class="catalog__banner"
        >
          <v-image :just-one="true" :src="banner" :alt="title" responsive />
        </div>
        <see-more v-if="isPresent(seeMoreText)" :text="seeMoreText" />

        <toolbar :case-scope="false" @updateDeviceSlug="updateDeviceSlug" />
        <div
          v-if="!$fetchState.pending && !products.length"
          class="info-panel info-panel--solo center"
        >
          <p>{{ $t('search.empty.no_products_found') }}</p>
        </div>
        <br />
        <div data-hook="taxon_products">
          <products-grid
            :case-device="caseDevice"
            :case-device-id="caseDeviceId"
            :products="products"
            :send-gtm-data="sendGtmData"
            :device-slug="deviceSlugData"
          />
          <pagination
            :total-pages="pagination.totalPages"
            :current-page="pagination.currentPage"
          />
        </div>
      </div>
    </div>

    <lazy-hydrate
      v-slot="{ hydrated }"
      :trigger-hydration="$store.state.userInteracted"
    >
      <shelf
        v-if="hydrated"
        :shelf-products="{
          type: 'rich_relevance',
          placement_name: 'category_page.rr1',
          richProducts: products,
          taxon,
        }"
        :class="'shelf--grey'"
      />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import isPresent from '~/utils/isPresent'
import watchPaginable from '~/mixins/watch-paginable'
import ProductsGrid from '~/components/ProductsGrid.vue'
import Navigator from '~/components/catalog/navigator/Navigator.vue'
import Toolbar from '~/components/catalog/toolbar/Toolbar.vue'
import SeeMore from '~/components/catalog/SeeMore.vue'
import Pagination from '~/components/catalog/Pagination.vue'
import pushDataLayer from '~/mixins/gtm/push-data-layer'
import Shelf from '~/components/pages/shelf/Shelf.vue'
import VImage from '~/components/VImage.vue'

import { GTM_PRODUCTS_QUANTITY } from '~/config'

export default {
  name: 'CatalogPage',
  components: {
    ProductsGrid,
    Navigator,
    Toolbar,
    SeeMore,
    Pagination,
    Shelf,
    LazyHydrate,
    VImage,
  },
  mixins: [watchPaginable, pushDataLayer],
  props: {
    noData: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    banner: {
      type: String,
      default: '',
    },
    taxon: {
      type: [Object, String],
      default: null,
    },
    productFilter: {
      type: [Object, String],
      default: null,
    },
    caseDevice: {
      type: String,
      default: '',
    },
    seeMoreText: {
      type: String,
      default: '',
    },
    caseScope: {
      type: Boolean,
      default: true,
    },
    sendGtmData: {
      type: Boolean,
      default: false,
    },
    deviceSlug: {
      type: String,
      default: '',
    },
  },
  async fetch() {
    try {
      const { order, page: pageParam } = this.$route.query

      const taxonParam = { taxon: this.taxonSlug }
      const productFilterParam = { product_filter: this.productFilterSlug }
      const sortParam = { sorting: order || null }
      const paginationParam = { page: pageParam || 1 }
      const storeInfo = this.$store.getters.storeInfo
      const caseDeviceParam = { case_device: this.caseDeviceParam }
      const params = {
        ...storeInfo,
        ...taxonParam,
        ...caseDeviceParam,
        ...sortParam,
        ...paginationParam,
        ...productFilterParam,
        per_page: this.$device?.isDesktop ? 21 : 20,
      }

      if (this.$store.state.skipStoreValidation) {
        params.skip_product_store = true
      }

      const response = await this.$axios.$get('/products/taxons', { params })
      const {
        page,
        per_page: perPage,
        total_count: totalProducts,
        case_device_id: caseDeviceId,
      } = response

      const products = response.products

      // testing gifs
      // products = products.map((prod) => {
      //   prod.gif_image =
      //     'https://static-staging.gocase.com.br/gif_images/2/original/generate.gif'
      //   return prod
      // })

      const pagination = {
        currentPage: page,
        totalPages: Math.ceil(totalProducts / perPage),
      }

      this.products = products
      this.pagination = pagination
      this.caseDeviceId = caseDeviceId
    } catch (err) {
      this.$nuxt.context.error({
        statusCode: 404,
        message: 'Taxon not found',
      })
    }
  },
  data() {
    return {
      pagination: { currentPage: 0, totalPages: 0 },
      products: [],
      caseDeviceId: null,
      deviceSlugData: this.deviceSlug,
    }
  },
  computed: {
    taxonSlug() {
      return this.getSlug(this.taxon)
    },
    productFilterSlug() {
      return this.getSlug(this.productFilter)
    },
    productIds() {
      return this.products.map((product) => product.id)
    },
    caseDeviceParam() {
      const { device, case_type: caseType } = this.$route.query
      return (
        this.caseDevice || (caseType && device ? `${caseType}-${device}` : '')
      )
    },
  },
  watch: {
    products() {
      if (
        this.pagination.currentPage === 1 &&
        isPresent(this.taxon) &&
        this.$store.state.userInteracted
      )
        this.sendToGtm()
    },
    '$store.state.userInteracted'() {
      this.sendToGtm()
    },
  },
  methods: {
    isPresent,
    updateDeviceSlug(deviceSlug) {
      this.deviceSlugData = deviceSlug
    },
    getSlug(object) {
      const taxonSlug = this.$route?.params?.pathMatch

      return typeof object === 'object'
        ? object?.slug || object?.permalink
        : object || taxonSlug || ''
    },
    sendToGtm() {
      if (!this.taxon) return

      this.pushDataLayer({
        event: 'genericEvent',
        eventCategory: 'navigation',
        eventAction: 'viewTaxon',
        eventValue: {
          taxonName: this.taxon.name,
          taxonId: this.taxon.id,
          productsIds: this.productIds.slice(0, GTM_PRODUCTS_QUANTITY),
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/css/_content.scss';
@import '~/assets/css/_catalog.scss';
</style>
