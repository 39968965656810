<template>
  <div class="pages">
    <div v-if="isAdaptedPage">
      <div class="container container__adapted-page">
        <h1 v-if="object.title">{{ object.title }}</h1>
        <v-content>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="html"></div>
        </v-content>
      </div>
    </div>
    <div v-else-if="template === 'v4'">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="html"></div>
      <catalog-page
        :title="title"
        :see-more-text="pageDescription"
        :product-filter="productFilter"
        :case-device="caseDevice"
        :taxon="taxonSlug"
        :send-gtm-data="true"
      />
    </div>
    <div v-else>
      <h1 v-if="Array.isArray(showcases)">{{ object.title }}</h1>
      <banners v-if="banners.length" :banners="banners" :title="object.title" />
      <brand-message
        v-if="
          (!showHomeWidget && homePage && homeSlogan) ||
          (devicePage && homeSlogan)
        "
        :home-slogan="homeSlogan"
      />
      <home-widget
        v-if="showHomeWidget"
        class="only-mobile"
        :widget="homeWidget"
      />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="!devicePage" v-html="html"></div>
      <spree-page
        v-if="isPresent(minibanners) || isPresent(showcases)"
        :minibanners="minibanners"
        :showcases="showcases"
        :taxon-slug="taxonSlug"
        :home-reviews="homeReviews"
        :home-review-block="homeReviewBlock"
        :home-brand-block="homeBrandBlock"
        :home-middle-shelves="homeMiddleShelves"
        :html="html"
        :device-page="devicePage"
      />
    </div>
  </div>
</template>

<script>
import isPresent from '~/utils/isPresent'
import Banners from '~/components/pages/banners/Banners.vue'
import VContent from '~/components/shared/v-content/VContent'

const SpreePage = () =>
  import(
    /* webpackChunkName: "spree-page" */ '~/components/pages/SpreePage.vue'
  )

const CatalogPage = () =>
  import(
    /* webpackChunkName: "catalog-page" */ '~/components/catalog/CatalogPage.vue'
  )

const HomeWidget = () =>
  import(
    /* webpackChunkName: "home-widget" */ '~/components/pages/home-widget/HomeWidget.vue'
  )

const BrandMessage = () =>
  import(
    /* webpackChunkName: "home-widget" */ '~/components/pages/brand-message/BrandMessage.vue'
  )

export default {
  components: {
    SpreePage,
    Banners,
    CatalogPage,
    HomeWidget,
    BrandMessage,
    VContent,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    taxonSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      minibanners: null,
      banners: null,
      template: '',
      html: '',
      productFilter: '',
      caseDevice: '',
      homeWidget: null,
      homeSlogan: null,
      homeReviews: null,
      homeReviewBlock: null,
      homeBrandBlock: null,
      homeMiddleShelves: null,
    }
  },
  computed: {
    showLicenseBanners() {
      return (
        this.$isGlobalStore &&
        this.homePage &&
        !['b', 'c'].includes(this.$route?.query?.lbt)
      )
    },
    devicePage() {
      return this.isAndroidPage || this.isIosPage
    },
    isAndroidPage() {
      return this.$route.fullPath?.includes('/android')
    },
    isIosPage() {
      return this.$route.fullPath?.includes('/ios')
    },
    isAdaptedPage() {
      return [
        '/privacy-policy',
        '/return-policy',
        '/politica-de-privacidade',
        '/termos-de-uso',
      ].includes(this.$route.path)
    },
    homePage() {
      return this.$route.path === '/'
    },
    showHomeWidget() {
      return this.homePage && isPresent(this.homeWidget)
    },
    pageDescription() {
      return (
        // eslint-disable-next-line camelcase
        this.object?.page_description ||
        // eslint-disable-next-line camelcase
        this.object?.seo_description
      )
    },
    haveScriptToLoad() {
      return this.html && this.html.match('<script')
    },
    usePageHTMLSetter() {
      const klavyoForm = document.querySelectorAll('[class^="klaviyo-"]')
      const omForm = document.querySelectorAll('[class^="om-"]')
      return !(klavyoForm.length || omForm.length)
    },
  },
  created() {
    try {
      const { template } = this.object

      if (template === 'v4') {
        const {
          product_filter: productFilter,
          case_device: caseDevice,
          see_more_text: seeMoreText,
          title,
          html,
        } = this.object

        this.title = title
        this.html = html
        this.productFilter = productFilter
        this.caseDevice = caseDevice
        this.template = template
        this.seeMoreText = seeMoreText
      } else {
        const {
          banners,
          mini_banners: minibanners,
          showcases,
          title,
          html,
          home_widget: homeWidget,
          home_slogan: homeSlogan,
          home_reviews: homeReviews,
          home_review_block: homeReviewBlock,
          home_brand_block: homeBrandBlock,
          home_middle_shelves_block: homeMiddleShelves,
        } = this.object
        this.banners = banners
        this.minibanners = minibanners
        this.showcases = showcases
        this.title = title
        this.html = html
        this.template = template
        this.homeWidget = homeWidget
        this.homeSlogan = homeSlogan
        this.homeReviews = homeReviews
        this.homeReviewBlock = homeReviewBlock
        this.homeBrandBlock = homeBrandBlock
        this.homeMiddleShelves = homeMiddleShelves
      }

      if (this.devicePage) {
        if (this.isIosPage) {
          this.homeSlogan = 'As melhores capinhas para o seu iPhone!'
        }
        if (this.isAndroidPage) {
          this.homeSlogan = 'Mais de 100 modelos de Android disponíveis!'
        }
      }
    } catch (err) {
      this.$nuxt.context.error({
        statusCode: 404,
        message: 'Page not Found',
      })
    }
  },
  methods: {
    isPresent,
    userInteractedCalls() {
      if (this.haveScriptToLoad && this.usePageHTMLSetter) {
        this.pageHTMLSetter()
      }
    },
    pageHTMLSetter() {
      const setInnerHTML = function (elm, html) {
        elm.innerHTML = html
        Array.from(elm.querySelectorAll('script')).forEach((oldScript) => {
          const newScript = document.createElement('script')
          Array.from(oldScript.attributes).forEach((attr) =>
            newScript.setAttribute(attr.name, attr.value)
          )

          if (
            Array.from(oldScript.attributes)
              .map((os) => os.name)
              .includes('src')
          ) {
            newScript.appendChild(document.createTextNode(oldScript.innerHTML))
            oldScript.parentNode.replaceChild(newScript, oldScript)
          } else {
            setTimeout(() => {
              newScript.appendChild(
                document.createTextNode(oldScript.innerHTML)
              )
              oldScript.parentNode.replaceChild(newScript, oldScript)
            }, 1000)
          }
        })
      }

      setInnerHTML(document.querySelector('.pages'), this.html)
    },
  },
}
</script>

<style lang="scss">
@import '~/assets/css/grid.scss';
.pages h1 {
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
  font-size: 40px;
}

.container__adapted-page {
  font-size: 17px;
  ul {
    list-style-type: inherit;

    li {
      margin-left: 20px;

      ul {
        list-style-type: inherit;
      }
    }
  }
}
</style>
