<template>
  <ul v-if="hasPages" class="pagination">
    <template v-if="currentPage > 1">
      <li class="first" @click="onClickItem(1)">
        <nuxt-link :to="getPageURL(1)"> « {{ $t('page.first') }} </nuxt-link>
      </li>
      <li class="prev" @click="onClickItem(prevPage)">
        <nuxt-link :to="getPageURL(prevPage)" rel="prev">
          ‹ {{ $t('page.prev') }}
        </nuxt-link>
      </li>
    </template>

    <li v-if="currentPage >= 2" class="page gap disabled">
      <a href="#" onclick="return false;" rel="nofollow">…</a>
    </li>

    <li
      v-for="page in maxPageList"
      :key="page"
      :data-page="page"
      :class="['page', { active: page == currentPage }]"
      @click="onClickItem(page)"
    >
      <nuxt-link :to="getPageURL(page)">
        {{ page }}
      </nuxt-link>
    </li>

    <li
      v-if="totalPages >= currentPage && currentPage < totalPages - 1"
      class="page gap disabled"
    >
      <a href="#" onclick="return false;" rel="nofollow">…</a>
    </li>

    <template v-if="currentPage < totalPages">
      <li class="next_page" @click="onClickItem(nextPage)">
        <nuxt-link rel="next" :to="getPageURL(nextPage)">
          {{ $t('page.next') }} ›
        </nuxt-link>
      </li>
      <li class="last next" @click="onClickItem(totalPages)">
        <nuxt-link :to="getPageURL(totalPages)">
          {{ $t('page.last') }} »
        </nuxt-link>
      </li>
    </template>
  </ul>
</template>

<script>
import appendUrlParam from '~/utils/appendUrlParam'

export default {
  props: {
    /** The current active page */
    currentPage: {
      type: Number,
      default: null,
      required: true,
    },
    /** The number total of pages */
    totalPages: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  computed: {
    hasPages() {
      return this.totalPages > 1
    },
    prevPage() {
      return this.currentPage - 1
    },
    nextPage() {
      return this.currentPage + 1
    },
    baseUrl() {
      const currentPath = this.$route.path
      const queryParams = { ...this.$route.query }
      delete queryParams.page
      return appendUrlParam(currentPath, queryParams)
    },
    maxPageList() {
      return [
        this.currentPage >= 2 ? this.currentPage - 1 : 0,
        this.currentPage,
        this.currentPage + 1 <= this.totalPages ? this.currentPage + 1 : 0,
      ].filter((p) => p > 0)
    },
  },

  methods: {
    getPageURL(page) {
      return appendUrlParam(this.baseUrl, { page })
    },
    onClickItem(page) {
      /**
       * Triggers when page item is clicked
       *
       * @param {Number} page page number
       */
      this.$emit('change', page)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/_pagination.scss';
</style>
