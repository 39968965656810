import { render, staticRenderFns } from "./VContent.vue?vue&type=template&id=37278e8d&scoped=true&functional=true&"
var script = {}
import style0 from "./VContent.vue?vue&type=style&index=0&id=37278e8d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "37278e8d",
  null
  
)

export default component.exports