<template>
  <nav id="product-filters" class="category-tree sidebar-widget" data-hook="">
    <text-skeleton v-if="loading" />
    <transition name="fade">
      <div v-if="!loading" class="product-filters__menu">
        <product-filter-menu-item
          v-for="productFilter in productFilters"
          :key="productFilter.id"
          :product-filter="productFilter"
          :selected-product-filter="currentProductFilter"
        />
      </div>
    </transition>
  </nav>
</template>

<script>
import ProductFilterMenuItem from '~/components/catalog/navigator/ProductFilterMenuItem.vue'
import TextSkeleton from '~/components/shared/text-skeleton/TextSkeleton.vue'

export default {
  components: {
    ProductFilterMenuItem,
    TextSkeleton,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    productFilters: {
      type: Array,
      default: () => [],
    },
    currentProductFilter: {
      type: Object,
      default: null,
    },
  },
}
</script>
