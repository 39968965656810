<template>
  <div v-if="shouldDisplay" class="section section--universal">
    <h4 class="sidebar-widget__title refine-nav__subtitle">
      {{ taxon.name }}
    </h4>
    <div class="category-tree__children">
      <nuxt-link
        v-for="child in children"
        :key="child.id"
        :class="[
          'category-tree__item',
          { 'category-tree__item--active': isSelected(child.id) },
        ]"
        :to="navigatorLink(child.permalink)"
      >
        <div class="category-tree__item-arrow"></div>
        {{ child.name }}
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import toQueryString from '~/utils/toQueryString'

export default {
  name: 'TaxonMenuItem',
  props: {
    taxon: {
      type: Object,
      required: true,
    },
    selectedId: {
      type: [Number, String],
      default: '',
    },
  },

  computed: {
    children() {
      return this.taxon?.children ?? []
    },
    shouldDisplay() {
      return this.children.length > 0
    },
  },
  methods: {
    isSelected(id) {
      return Number(this.selectedId) === Number(id)
    },
    navigatorLink(permalink) {
      const removeBadQueryParams = () => permalink.split('?')[0]
      permalink = removeBadQueryParams(permalink)
      return `/${permalink}?${toQueryString(this.$route.query)}`
    },
  },
}
</script>

<style lang="scss" scoped>
.category-tree__item {
  display: flex;
  align-items: center;
}
.category-tree__item-arrow {
  margin-left: 5px;
  margin-right: 5px;
  width: 5px;
  height: 7px;
  color: #555;
  background: url('~@/assets/images/icons/header-menu/arrow-right.svg')
    center/contain no-repeat;
}
</style>
