<template>
  <div class="image__wrapper">
    <div v-if="isLoading" class="image__loading-backdrop"></div>
    <img
      v-srcset="{ srcsetStructure, isMobile: true, ignore: ignoreSrcset }"
      v-lazyload="{ ignore: !lazy }"
      v-bind="$attrs"
      :class="[$attrs.class, 'only-mobile', { image__responsive: responsive }]"
      v-on="$listeners"
      @load="onLoad"
      @error="onError"
    />
    <img
      v-if="!justOne"
      v-srcset="{ srcsetStructure, isMobile: false, ignore: ignoreSrcset }"
      v-lazyload="{ ignore: !lazy }"
      v-bind="$attrs"
      :class="[$attrs.class, 'only-desktop', { image__responsive: responsive }]"
      v-on="$listeners"
      @load="onLoad"
      @error="onError"
    />
  </div>
</template>

<script>
import isPresent from '~/utils/isPresent'
export default {
  name: 'VImage',
  props: {
    responsive: {
      type: Boolean,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    srcsetStructure: {
      type: Object,
      default: () => {},
    },
    justOne: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ignoreSrcset() {
      return !this.isPresent(this.srcsetStructure)
    },
  },
  methods: {
    isPresent,
    onLoad() {
      this.isLoading = false
    },
    onError() {
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
img.image__responsive {
  width: 100%;
  object-fit: contain;
}
</style>
