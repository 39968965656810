<template>
  <cms-page
    v-if="type === 'cms-page'"
    :object="object"
    :taxon-slug="taxonSlug"
  />

  <product-filter
    v-else-if="type === 'product-filter'"
    :product-filter="object"
    :main-description="pageDescription"
  />

  <promotion-event-page
    v-else-if="type === 'promotion-event'"
    :promotion-event="object"
  />
</template>

<script>
import appendUrlParam from '~/utils/appendUrlParam'
import CmsPage from '~/components/pages/CmsPage.vue'
import PromotionEventPage from '~/components/pages/promotion-event-page/PromotionEventPage.vue'
import ProductFilter from '~/components/ProductFilter.vue'

export default {
  preFetchAsyncData: false, // already did in index, cant pretech here because need of some requests before
  components: {
    CmsPage,
    ProductFilter,
    PromotionEventPage,
  },
  async asyncData({
    $axios,
    store,
    params: { pathMatch: taxonSlug },
    error,
    app,
    res,
    $sentry,
  }) {
    if (process.server && app._EVIL_DONT_DO_asyncDataPrefetch) {
      return app._EVIL_DONT_DO_asyncDataPrefetch
    }

    const storeInfo = store?.getters?.storeInfo

    try {
      taxonSlug = taxonSlug || 'home'

      const endpointUrl = appendUrlParam(`/static/${taxonSlug}`, storeInfo)

      const { object, meta, type } = await $axios.$get(endpointUrl, {
        cache: {
          readOnError: true,
          ignoreCache: false,
        },
      })

      const { meta_description: metaDescription } = meta
      let { meta_title: metaTitle } = meta
      let { title: mainTitle } = object
      const { page_description: pageDescription } = object

      // Perf test: First banner via ImageKit
      if (object?.banners?.length > 0) {
        const b = object.banners[0] || {}
        const IMAGE_KIT_HOST = 'https://ik.imagekit.io/gocase'
        const STATIC_HOST = 'https://static-production.gocase.com.br'

        // eslint-disable-next-line camelcase
        b.mobile_image = b?.mobile_image?.replace(STATIC_HOST, IMAGE_KIT_HOST)
        // eslint-disable-next-line camelcase
        b.desktop_image = b?.desktop_image?.replace(STATIC_HOST, IMAGE_KIT_HOST)
      }
      // Perf test: First banner via ImageKit

      // Perf test: first banner image - START
      if (res && object?.banners?.length > 0) {
        const b = object.banners[0]

        // eslint-disable-next-line camelcase
        res.setHeader('x-link', `<${b?.mobile_image}>; rel=preload; as=image`)
        // eslint-disable-next-line camelcase
        // res.setHeader('x-link2', `<${b?.desktop_image}>; rel=preload; as=image`)
      }
      // Perf test: first banner image - END

      metaTitle = metaTitle?.replace(' - Gocase', '')
      mainTitle = mainTitle?.replace(' - Gocase', '')

      return {
        object,
        meta,
        type,
        metaTitle,
        metaDescription,
        pageDescription,
        mainTitle,
        taxonSlug,
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      $sentry.captureException(err)

      const status = err?.response?.status

      error({
        statusCode: status || 404,
        message:
          'Page not found - ' + taxonSlug + ' - ' + status ? 'Api' : 'Code',
      })

      return {}
    }
  },
  data() {
    return { type: '', object: {}, meta: {}, taxonSlug: '', preloads: [] }
  },
  created() {
    this.preloadResource({ type: 'banner', resource: this.object?.banners })
  },
  methods: {
    preloadResource({ type, resource }) {
      if (type === 'banner' && Array.isArray(resource)) {
        const banner = resource[0] || {}

        const bannerImg = this.$device.isMobile
          ? banner.mobile_image
          : banner.desktop_image

        this.preloads.push({
          rel: 'preload',
          as: 'image',
          href: bannerImg,
        })
      }
    },
  },
  head() {
    return {
      title:
        (this.metaTitle || this.mainTitle || 'Home') +
        (this.$route.query.device ? ` - ${this.$route.query.device}` : '') +
        (this.$route.query.case_type
          ? ` - ${this.$route.query.case_type}`
          : ''),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            // eslint-disable-next-line camelcase
            `${
              this.metaDescription
                ? this.metaDescription
                : this.$t('seo.meta_description')
            }` +
            (this.$route.query.device ? ` - ${this.$route.query.device}` : '') +
            (this.$route.query.case_type
              ? ` - ${this.$route.query.case_type}`
              : ''),
        },
      ],
      link: this.preloads,
    }
  },
}
</script>
